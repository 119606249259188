import styled from "styled-components";
import { Link } from "gatsby";

export const StyledCard = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 1200px;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid #d1d1d1; */
  /* border-radius: 15px; */
  overflow: hidden;
  width: 100%;
  /* height: 400px; */
  background: #fff;
  /* padding: 0; */
  margin: 1em 0;
  padding-bottom: 2em;
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
  border-radius: 12px;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 50%;
    /* margin-top: 50px; */
    margin-bottom: 50px;
    box-shadow: none;

    padding: 2em 3em;
  }

  /* a + a {
    margin-top: 1em;
  } */
  p {
    margin-bottom: 2em;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: 400;
    /* padding: 1em 0; */
    margin-top: 1.5em;
    margin-bottom: 1em;
    /* margin: 0; */

    @media ${({ theme }) => theme.breakpoints.md} {
      font-size: 1.75rem;
    }
  }

  /* h1,
  a {
    padding: 0 1em;
  } */
`;

export const StyledCardContent = styled.div`
  align-self: flex-start;
  padding: 0 1.5em;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease 0s;

  &:hover {
    /* text-decoration: underline; */
    border-bottom: 2px solid black;
  }
`;
