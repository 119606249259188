import styled from "styled-components";

export const ProjectSection = styled.section`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "column"};
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  align-items: ${({ align }) => (align ? align : "left")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "left")};
  flex-wrap: wrap;
  /* margin-bottom: 3em; */

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 1em 0;
  }

  .mdx-inline-image {
    width: 100%;
    margin-top: 2em;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    border-radius: 12px;
    overflow: hidden;
    p {
      margin-top: 0;
    }
  }

  /* Autolink styling */
  h2 {
    a {
      float: left;
      padding-right: 4px;
      margin-left: -20px;
    }
    svg {
      visibility: hidden;
    }
    &:hover {
      a {
        svg {
          visibility: visible;
        }
      }
    }
  }
`;

export const StyledProjectHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  width: 100%;
  margin-right: auto;
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 0;
  }

  @media (min-width: 1070px) {
    align-items: center;
    flex-direction: row;

    h1 {
      margin-bottom: 1em;
    }
  }
`;

export const StyledProjectButtons = styled.div`
  /* display: block; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around;
  justify-content: center;
  flex-wrap: wrap; */
  margin-top: 1em;

  @media ${({ theme }) => theme.breakpoints.xs} {
    /* display: block; */
    flex-direction: row;
  }

  /* @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 1em;
  } */

  a {
    font-size: 1em;
    min-width: 115px;
    /* margin-bottom: 1em; */

    /* @media ${({ theme }) => theme.breakpoints.sm} {
      font-size: 0.85em;
    } */

    @media ${({ theme }) => theme.breakpoints.md} {
      font-size: 1em;
    }
  }

  a + a {
    margin-left: 0;
    margin-top: 1em;

    @media ${({ theme }) => theme.breakpoints.xs} {
      margin-left: 1em;
      margin-top: 0;
    }
  }
`;
