import React from "react";
import { Link } from "gatsby";
import { Image } from "../components";
import {
  H1,
  P,
  StyledCard,
  StyledCardContent,
  StyledLink,
} from "../components/styles";

export const Card = ({ link, image, heading, excerpt }) => (
  <StyledCard>
    <Link to={link}>
      <Image image={image} type="card" alt="feature" />
    </Link>
    <StyledCardContent>
      <Link to={link}>
        <H1>{heading}</H1>
        {excerpt && <P>{excerpt}</P>}
      </Link>
      <StyledLink to={link}>
        <strong>
          View Project <span>›</span>
        </strong>
      </StyledLink>
    </StyledCardContent>
  </StyledCard>
);
