import styled from "styled-components";

export const Content = styled.div`
  /* margin: 3em 0; */
  margin: 1em 0 1em 0;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 75%;
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    width: 65%;
  }

  p + p {
    margin-top: 1.85em;
  }

  h2 {
    margin-bottom: 0;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  max-width: ${({ page }) => (page === "about" ? "800px" : "inherit")};

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    /* gap: 2em; */
    /* Flexbox gap above not supported in Safari yet */
    div + div {
      margin-left: ${({ page }) => (page === "about" ? "2em" : 0)};
    }
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    /* gap: 3em; */
    /* Flexbox gap above not supported in Safari yet */
    div + div {
      margin-left: ${({ page }) => (page === "about" ? "3em" : 0)};
    }
  }
  @media ${({ theme }) => theme.breakpoints.xl} {
    /* gap: 5em; */
    /* Flexbox gap above not supported in Safari yet */
    div + div {
      margin-left: ${({ page }) => (page === "about" ? "5em" : 0)};
    }
  }

  div {
    width: 100%;
  }

  img.gatsby-resp-image-image {
    border-radius: 0.3rem;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2));
    overflow: hidden;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
  }
`;
