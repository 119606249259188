import React from "react";
// import { Button } from ".";
import { StyledForm, FormGroup, ContactFormButton } from "./styles";

export const ContactForm = () => {
  return (
    <StyledForm
      name="contact"
      method="POST"
      data-netlify="true"
      action="/contact-confirmation"
    >
      <input type="hidden" name="form-name" value="contact" />
      <FormGroup>
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </FormGroup>
      <FormGroup>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" id="email" />
      </FormGroup>
      <FormGroup>
        <label htmlFor="subject">Subject</label>
        <input type="text" name="subject" id="subject" />
      </FormGroup>
      <FormGroup>
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" cols="30" rows="10" />
      </FormGroup>
      {/* <Button type="submit">Send</Button> */}
      <ContactFormButton type="submit">Send</ContactFormButton>
    </StyledForm>
  );
};
