import React from "react";
import { Link } from "gatsby";
import {
  Container,
  StyledHeader,
  HeaderEl,
  LogoWrapper,
  NavWrapper,
} from "../components/styles";

export const Header = ({ siteTitle, siteDescription, pathname }) => {
  // console.log("Header => pathname:", pathname);

  return (
    // <Wrapper>
    <StyledHeader pathname={pathname} as="header">
      <Container>
        <HeaderEl>
          <LogoWrapper>
            <Link to="/">{siteTitle}</Link>
            <p>{siteDescription}</p>
          </LogoWrapper>
          {/* <Social /> */}
          <NavWrapper>
            <ul>
              <li>
                {/* <Link to="/about">About</Link> */}
                <Link to="/about">
                  <span data-hover="About">About</span>
                </Link>
              </li>
              <li>
                {/* <Link to="/projects">Projects</Link> */}
                <Link to="/projects">
                  <span data-hover="Projects">Projects</span>
                </Link>
              </li>
              <li>
                {/* <Link to="/contact">Contact</Link> */}
                <Link to="/contact">
                  <span data-hover="Contact">Contact</span>
                </Link>
              </li>
            </ul>
          </NavWrapper>
        </HeaderEl>
      </Container>
    </StyledHeader>
    // </Wrapper>
  );
};
