import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StyledTechStack } from "./styles";

export const TechStack = ({ techStack, repository, liveUrl }) => {
  const icons = useStaticQuery(graphql`
    query {
      gatsby: file(relativePath: { eq: "gatsby_small.svg" }) {
        publicURL
      }
      netlify: file(relativePath: { eq: "netlify_small.svg" }) {
        publicURL
      }
      react: file(relativePath: { eq: "react.svg" }) {
        publicURL
      }
      graphql: file(relativePath: { eq: "graphql.svg" }) {
        publicURL
      }
      api: file(relativePath: { eq: "api09.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledTechStack>
      {techStack && (
        <div>
          <strong>Tech Stack</strong>
          <ul>
            {techStack.map((stack) => {
              switch (true) {
                case /React/.test(stack):
                  return (
                    <li key={stack}>
                      {stack} <img src={icons.react.publicURL} alt="react" />
                    </li>
                  );
                case /Gatsby/.test(stack):
                  return (
                    <li key={stack}>
                      {stack} <img src={icons.gatsby.publicURL} alt="gatsby" />
                    </li>
                  );
                case /Netlify/.test(stack):
                  return (
                    <li key={stack}>
                      {stack}
                      <img src={icons.netlify.publicURL} alt="netlify" />
                    </li>
                  );
                case /GraphQL/.test(stack):
                  return (
                    <li key={stack}>
                      {stack}
                      <img src={icons.graphql.publicURL} alt="graphql" />
                    </li>
                  );
                case /.*(\sAPI)/.test(stack):
                  return (
                    <li key={stack}>
                      {stack} <img src={icons.api.publicURL} alt="api" />
                    </li>
                  );
                default:
                  return <li key={stack}>{stack}</li>;
              }
            })}
          </ul>
        </div>
      )}
      {repository && (
        <div>
          <strong>Code</strong>
          <ul>
            <li>
              <a href={repository} target="_blank" rel="noopener noreferrer">
                Repository
              </a>
            </li>
          </ul>
        </div>
      )}
      {liveUrl && (
        <div>
          <strong>Live</strong>
          <ul>
            <li>
              <a href={liveUrl} target="_blank" rel="noopener noreferrer">
                View Site
              </a>
            </li>
          </ul>
        </div>
      )}
    </StyledTechStack>
  );
};
