// import { wrapPageElement as wrap } from "./root-wrapper";

import { rootWrapper } from "./root-wrapper";

// fix for scroll to top of page when link is cliked (i.e. in footer)
export const onPreRouteUpdate = () => {
  document.body.scrollTop = 0;
};

// export const wrapPageElement = wrap;

export const wrapPageElement = rootWrapper;
