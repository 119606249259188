import React from "react";
import styled from "styled-components";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

export const ContactLinks = ({ page }) => (
  <ContactLinksWrapper page={page}>
    <ul>
      <li>
        <a
          color="#EA4335"
          href="mailto:petercurcio.dev@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineMail style={{ fill: "#db4437" }} />
          {page === "contact" && <span className="link-text">Email me</span>}
        </a>
      </li>
      <li>
        <a
          color="#000000"
          href="https://github.com/petercurcio/"
          target="_blank"
          rel="noreferrer"
        >
          <FaGithub />
          {page === "contact" && (
            <span className="link-text">Check out my GitHub</span>
          )}
        </a>
      </li>
      <li>
        <a
          color="#0A66C2"
          href="https://www.linkedin.com/in/petercurcio/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin style={{ fill: "#0a66c2" }} />
          {page === "contact" && (
            <span className="link-text">Connect on LinkedIn</span>
          )}
        </a>
      </li>
      <li>
        <a
          color="#1DA1F2"
          href="https://twitter.com/PeterACurcio"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter style={{ fill: "#1da1f2" }} />
          {page === "contact" && (
            <span className="link-text">Let's chat on Twitter</span>
          )}
        </a>
      </li>
    </ul>
  </ContactLinksWrapper>
);

const ContactLinksWrapper = styled.div`
  width: 100%;

  li {
    list-style-type: none;
  }

  display: flex;

  justify-content: space-around;
  /* padding-top: 2rem; */

  ul {
    display: flex;
    flex-direction: ${({ page }) => (page === "contact" ? "column" : "row")};
    justify-content: space-between;
    /* align-items: flex-start; */
    align-items: ${({ page }) =>
      page === "contact" ? "flex-start" : "center"};
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-right: auto;

    li {
      padding: 0;
      /* margin-bottom: 1em; */
      margin: 0;
      height: 24px;

      a {
        /* color: #999; */
        color: #333;
        font-size: 1rem;
        font-weight: 400;
        opacity: 1;
        transition: 0.3s;

        display: flex;
        justify-content: center;
        align-items: center;

        @media ${({ theme }) => theme.breakpoints.sm} {
          margin-bottom: 0;
          font-size: 1rem;
        }
      }

      a:hover {
        text-decoration: none;
        opacity: 1;
      }
    }

    li + li {
      /* margin-top: 1em; */
      margin-top: ${({ page }) => (page === "contact" ? "1em" : 0)};
      margin-left: ${({ page }) => (page === "contact" ? 0 : "1em")};
    }
  }

  svg {
    font-size: 2em;
  }

  .link-text {
    margin-left: 1em;
  }
`;
