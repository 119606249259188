import styled from "styled-components";

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.family.heading};
  margin-bottom: 3rem;

  /* transform: translateY(30px); */
  /* opacity: 0; */
  animation: slide-up 1000ms;

  @keyframes slide-up {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 3rem;
  }
`;

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["4xl"]};
  font-family: ${({ theme }) => theme.font.serif};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  /* margin-top: ${({ theme }) => theme.spacing[8]}; */
  margin: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[0]};
  line-height: ${({ theme }) => theme.lineHeight.none};
`;

export const P = styled.p`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-top: ${({ theme }) => theme.spacing[3]};
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
`;
