import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Footer, Header, Hero, Main } from ".";
import { ColoredDivider } from "./styles";

export const Layout = ({ children, pathname }) => {
  const { title, description } = useSiteMetadata();

  // console.log(pathname);

  const images = useStaticQuery(graphql`
    query {
      codeImg01: file(relativePath: { eq: "Coding_Outline.svg" }) {
        publicURL
      }
    }
  `);

  const darkMode = false;

  return (
    <>
      <div className={`site ${darkMode ? "dark-mode" : "light-mode"}`}>
        <Header
          siteTitle={title}
          siteDescription={description}
          pathname={pathname}
        />
        {pathname === "/" && <Hero />}
        {pathname === "/" && (
          <ColoredDivider as="section">
            <img
              style={{ textAlign: "center", margin: "0 auto", width: "20%" }}
              src={images.codeImg01.publicURL}
              alt="code-img-01"
            />
          </ColoredDivider>
        )}

        <Main className="main">{children}</Main>

        <Footer pathname={pathname} />
      </div>
    </>
  );
};
