import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #fff;

  /* margin-top: -500px; */
  /* padding: 1em 2em 2em 2em; */

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 2em 4em;
    margin-top: 75px;
    padding: 0;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    border-radius: 12px;
  }
`;
