import styled from "styled-components";
import { Container, Wrapper } from ".";

export const StyledFooter = styled(Wrapper)`
  /* background: ${({ theme }) => theme.colors.heroBackground}; */
  padding: 1em 2em;

  /* @media ${({ theme }) => theme.breakpoints.lg} {
    padding: 1em 2em;
  } */
`;

export const FooterContainer = styled(Container)`
  /* max-width: 100%;
margin: 0 auto; */
  padding: 2em 0 1em 0;
  border-top: 1px solid #e9e9e9;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  div {
    margin-top: 1em;
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;

    div {
      margin-top: 0;
    }
  }

  .react-footer-icon {
    animation: iconspin infinite 7s linear;

    @keyframes iconspin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
