import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1em auto;
  width: 100%;
  padding: 1em 0;

  /* background-color: hsl(0, 0%, 99%); */
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */

  @media ${(props) => props.theme.breakpoints.sm} {
    max-width: 600px;
    margin: 0 auto;
  }
  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 2em;
  }

  /* div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    label {
      flex-shrink: 1;
    }
    input {
      flex-grow: 1;
    }
  } */

  div + div {
    margin-top: 1em;
  }

  /* label {
    margin-right: 1em;
  } */

  label {
    font-size: 1rem;
    margin-bottom: 8px;
    color: hsl(220, 48%, 20%);
  }

  input,
  textarea {
    margin-top: 0.35em;
    padding: 0.75rem 1.25rem;
    border: 1px solid rgba(210, 214, 220, 1);
    border-radius: 0.375rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 3px rgba(118, 169, 250, 0.45);
      border: 1px solid rgba(164, 202, 254, 1);
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="week"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  textarea {
    background: 0 0;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    color: #000;
    display: block;
    font-size: 0.9em;
    margin: 0 0 15px;
    padding: 15px;
    transition: border-color 0.2s ease-in-out;
    -webkit-appearance: none;
    width: 100%;
  }

  textarea {
    margin-bottom: 1em;
  }

  button {
    margin-top: 1em;
    width: 100%;

    @media ${(props) => props.theme.breakpoints.sm} {
      width: fit-content;
    }
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* justify-content: space-between; */
  /* align-items: center; */
  align-items: normal;
  width: 100%;

  label {
    flex-shrink: 1;
  }
  input {
    flex-grow: 1;
    /* margin-left: 1em; */
    /* max-width: 475px; */
  }
  textarea {
    flex-grow: 1;
    /* max-width: 475px; */
  }
`;
