import styled from "styled-components";

export const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  /* padding-top: 2rem; */

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      /* margin-bottom: 1em; */
      margin: 0;
      height: 24px;

      a {
        /* color: #999; */
        color: #000;
        font-size: 1rem;
        font-weight: 400;
        opacity: 0.6;
        transition: 0.3s;

        @media ${({ theme }) => theme.breakpoints.sm} {
          margin-bottom: 0;
          font-size: 1.5rem;
        }
      }

      a:hover {
        text-decoration: none;
        /* color: ${(props) => props.color}; */
        /* color: #333; */
        opacity: 1;
      }
    }

    li + li {
      margin-left: 1em;
    }
  }
`;
