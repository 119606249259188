import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "./src/theme/theme";
import {
  CodeBlock,
  TechStack,
  ContactForm,
  ContactLinks,
} from "./src/components";
import {
  Container,
  Content,
  FullWidth,
  ProjectSection,
  PageContent,
  mdxInlineImageWrapper,
} from "./src/components/styles";

const GlobalStyle = createGlobalStyle`

  *, *:before, *:after {
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  :root {
    font-size: 16px;
  }

  html {
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
    font-family: ${({ theme }) => theme.font.body};
    color: ${({ theme }) => theme.colors.darkBlue};
    height: 100%;
  }

  body {
    /* scroll-behavior: smooth; */
    font-size: ${({ theme }) => theme.fontSize.base};
    line-height: ${({ theme }) => theme.lineHeight.loose};
    letter-spacing: ${({ theme }) => theme.letterSpacing.normal};
    background-color: '#f7fafc';
    color: ${({ theme }) => theme.colors.darkGray};
  }

  body, html {
    font-size: 1rem;
    line-height: 1.6;
    color: ${(props) => props.theme.colors.darkBlue};
    font-family: ${(props) => props.theme.fonts.family.body};
    max-width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
  }

  /* Sticky footer */
  /* .site and .main are set in Layout.js */
  /* See mokkapps.de/blog/sticky-footer-in-gatsbyjs-using-flexbox/ */
  .site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .main {
    flex-grow: 1;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul, a, button {
    font-family: ${(props) => props.theme.fonts.family.body};
    font-size: ${({ theme }) => theme.fontSize.base}; 
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-left: 1.45rem;
    margin-right: 0;
    margin-top: 1em;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    list-style-position: outside;
    list-style-image: none;
    list-style-type: disc;
  }

  img {
    max-width: 100%;
  }

  h1,h2,h3,h4,h5,h6 {
    line-height: 1;
    font-weight: bold;
  }

  p {
    ${"" /* font-size: 1rem; */}
    font-size: ${(props) => props.theme.fonts.size.body.sm};
    font-family: ${(props) => props.theme.fonts.family.body};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    letter-spacing: ${(props) => props.theme.fonts.letterSpacing.body};
    line-height: ${(props) => props.theme.fonts.lineHeight.body};
    color: ${({ theme }) => theme.colors.darkGray};
    margin-top: 2em;

    /* links in mdx */
    a {
      text-decoration: underline;
      color: dodgerblue;

      &:hover {
        opacity: .8;
      }
    }
  }  

  h1 {
    font-family: ${(props) => props.theme.fonts.family.heading};
    font-size: ${(props) => props.theme.fonts.size.heading.sm};

    @media ${(props) => props.theme.breakpoints.md} {
      font-size: ${(props) => props.theme.fonts.size.heading.md};
    }
    @media ${(props) => props.theme.breakpoints.lg} {
      font-size: ${(props) => props.theme.fonts.size.heading.lg};
    }
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    color: ${(props) => props.theme.colors.darkBlue};
    letter-spacing: ${(props) => props.theme.fonts.letterSpacing.heading};
    line-height: ${(props) => props.theme.fonts.lineHeight.heading};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
    margin: ${(props) => (props.margin ? props.margin : 0)};
    
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.family.subheading};
    font-size: ${(props) => props.theme.fonts.size.subheading.sm};

    @media ${(props) => props.theme.breakpoints.md} { 
      font-size: ${(props) => props.theme.fonts.size.subheading.md};
    }
    @media ${(props) => props.theme.breakpoints.lg} {
      font-size: ${(props) => props.theme.fonts.size.subheading.lg};
    }
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    color: ${(props) => props.theme.colors.darkBlue};
    letter-spacing: ${(props) => props.theme.fonts.letterSpacing.subheading};
    line-height: ${(props) => props.theme.fonts.lineHeight.subheading};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
    margin: ${(props) => (props.margin ? props.margin : 0)};
    margin-bottom: 1em;
  }

  h3,h4,h5,h6 {
    margin-top: 2em;
  }

  /* Body font for textarea in contact form */
  textarea {
    font-family: ${({ theme }) => theme.font.body};
  }

  a {
    text-decoration: none;
    color: #333;
  }

  ul {
    /* links in mdx */
    a {
      text-decoration: underline;
      color: dodgerblue;

      &:hover {
        opacity: .8;
      }
    }
  }

  code {
    /* background: #202933;
    color: #69c; */
    background: #f5f5f5;
    color: #444;
    padding: .25em .5em;
    border-radius: 5px;
  } */

  .gatsby-highlight {
    overflow: auto;
    /* margin: 50px 0; */
    margin: 1em 0;
    border-radius: 5px;
  }
  .gatsby-highlight pre[class*='language-'] {
    background-color: transparent;
    float: left;
    min-width: 100%;
    padding: 16px;
  }
  .highlight-line {
    background-color: rgb(53, 59, 69);
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.3em solid #65b3ff;
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
  .featured .gatsby-image-wrapper {
    border-radius: 12px;
  }

  /* About page */
  .profile-pic {
    border-radius: 12px;

    p {
      margin-top: 0;
    }
  }
  .about-text {
    margin-top: 2em;

    @media ${({ theme }) => theme.breakpoints.md} {
      margin-top: 0;
    }
  }
`;

const components = {
  pre: (props) => <div className="gatsby-highlight" {...props} />,
  code: CodeBlock,
  Container,
  Content,
  ContactForm,
  ContactLinks,
  FullWidth,
  PageContent,
  ProjectSection,
  TechStack,
  mdxInlineImageWrapper,
};

// wrapping entire application in ThemeProvide
export const rootWrapper = ({ element }) => (
  <>
    <Helmet>
      <script
        defer
        data-domain="petercurcio.dev"
        src="https://plausible.bbbpbj.com/js/plausible.js"
      ></script>
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <MDXProvider components={components}>{element}</MDXProvider>
    </ThemeProvider>
  </>
);
