import styled from "styled-components";

export const StyledTableOfContents = styled.ul`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 10vh;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  background: rebeccapurple;
  z-index: 1;

  h2 {
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    position: fixed;
    /* left: calc(50% + 625px); */
    left: calc(90%);
    top: 100px;
    /* max-height: fit-content; */
    max-height: 350px;

    /* width: fit-content; */
    width: 150px;

    display: flex;
    flex-wrap: wrap;
    padding: 1.5em;
    box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
    border-radius: 12px;
    /* background: none; */
    background: #fff;
  }

  li {
    line-height: ${({ theme }) => theme.lineHeight.tight};
    margin-top: ${({ theme }) => theme.spacing[3]};
  }

  h2 {
    display: none;

    @media ${({ theme }) => theme.breakpoints.xl} {
      display: block;
      line-height: 1.25em;
    }
  }
`;

export const InnerScroll = styled.div`
  overflow: hidden;
  /* overflow-y: scroll; */

  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  text-align: center;
  padding: 0;
  font-size: 0.85rem;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 0.5em;
    font-size: 1rem;
  }

  @media ${({ theme }) => theme.breakpoints.xl} {
    flex-direction: column;
    text-align: left;
  }

  li {
    list-style-type: none;
    margin: 0;
    padding: 0.5em;

    @media ${({ theme }) => theme.breakpoints.sm} {
      width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.xl} {
      padding: 0;
    }

    a {
      color: #fff;

      @media ${({ theme }) => theme.breakpoints.xl} {
        color: #333;
      }
    }
  }

  li + li {
    border-left: 1px dotted darkblue;

    @media ${({ theme }) => theme.breakpoints.xl} {
      border-left: none;
    }
  }
`;
