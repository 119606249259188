import styled from "styled-components";
import { Wrapper } from ".";

export const StyledMain = styled(Wrapper)`
  padding-top: 0;
  margin: 4em 0;
  flex: 1;

  /* To account for sticky header */
  /* margin-top: 10em; */

  @media ${({ theme }) => theme.breakpoints.md} {
    /* margin: 9.375em 0 2em 0; */
    /* margin: 3em 0 2em 0; */
  }
`;
