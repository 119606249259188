import styled from "styled-components";
import { Container } from ".";

export const StyledTechStack = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1em;
  /* margin-bottom: 2em; */
  padding: 2em;
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
  border-radius: 12px;

  @media ${({ theme }) => theme.breakpoints.sm} {
    div + div {
      margin-left: 2rem;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xlg} {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    gap: 2em;
    margin-left: 0;
    margin-right: 0;
    width: fit-content;
  }

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    /* tech stack icons */
    img {
      width: 18px;
      height: 18px;
      margin-left: 2px;
      vertical-align: text-top;
    }

    a {
      text-decoration: none;
      color: rebeccapurple;
      font-weight: bold;
      border-bottom: 2px solid transparent;
      transition: border-bottom 0.3s ease 0s;

      &:hover {
        /* text-decoration: underline; */
        border-bottom: 2px solid rebeccapurple;
      }
    }
  }
`;
