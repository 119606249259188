import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Social } from ".";
import { StyledFooter, FooterContainer } from "../components/styles";
import { GrCode } from "react-icons/gr";

export const Footer = ({ pathname }) => {
  const footerIcons = useStaticQuery(graphql`
    query {
      gatsby: file(relativePath: { eq: "gatsby_small.svg" }) {
        publicURL
      }
      netlify: file(relativePath: { eq: "netlify_small.svg" }) {
        publicURL
      }
      react: file(relativePath: { eq: "react.svg" }) {
        publicURL
      }
      ampersand: file(relativePath: { eq: "ampersand_3.svg" }) {
        publicURL
      }
    }
  `);

  // console.log("Footer =>", pathname);

  return (
    <StyledFooter as="footer">
      {/* <Container> */}
      <FooterContainer>
        <Link style={{ textDecoration: "none" }} to="/">
          <strong style={{ color: "#333" }}>PC</strong> <GrCode />
        </Link>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Made with </span>
          <img
            style={{ height: "24px", width: "24px", margin: "0 5px" }}
            src={footerIcons.gatsby.publicURL}
            alt="gatsby"
          />
          <img
            style={{ height: "18px", width: "18px", margin: "0" }}
            src={footerIcons.ampersand.publicURL}
            alt="ampersand"
          />
          <img
            className="react-footer-icon"
            style={{ height: "24px", width: "24px", margin: "0 5px" }}
            src={footerIcons.react.publicURL}
            alt="react"
          />
          <span>. Hosted on </span>
          <img
            style={{ height: "24px", width: "24px", marginLeft: "5px" }}
            src={footerIcons.netlify.publicURL}
            alt="netlify"
          />
        </div>
        <Social />
      </FooterContainer>
      {/* </Container> */}
      {/* {/\/projects/.test(pathname) && (
        <>
          <br />
          <br />
        </>
      )} */}
    </StyledFooter>
  );
};
