import styled from "styled-components";
import { Wrapper } from "../styles";

export const StyledHeader = styled(Wrapper)`
  /* background: ${(props) =>
    props.pathname === "/" ? props.theme.colors.heroBackground : "#fff"}; */

  padding: 1.5em;
  background: #fff;
  border-bottom: 1px solid #d5d5d5;
  /* position: fixed; */
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.xl} {
    /* padding: 1.5em 1em; */
    padding: 1em;
    max-height: 100px;
  }
`;

export const HeaderEl = styled.header`
  margin-bottom: 1.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* align-items: center; */

  @media ${({ theme }) => theme.breakpoints.sm} {
    flex-direction: row;
    align-items: center;
    /* padding: 2em 0; */
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkGray};
    font-weight: bold;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0 0 1em 0;
  padding: 0;

  @media ${({ theme }) => theme.breakpoints.sm} {
    margin: 0;
  }

  a,
  p {
    font-size: ${(props) => props.theme.fonts.size.body.sm};
    line-height: 1.25rem;
  }

  p {
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    margin: 0;
  }
`;

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: space-around;
  /* padding-top: 2rem; */

  ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        font-size: 0.85rem;

        @media ${({ theme }) => theme.breakpoints.md} {
          font-size: 1rem;
        }
        font-weight: 400;
        margin-bottom: 0;

        position: relative;
        display: inline-block;

        /* padding: 0; */
        padding: 0 0.1em;
        outline: none;
        color: #333;
        text-decoration: none;
        /* text-transform: uppercase; */
        letter-spacing: 1px;
        font-weight: 400;
        text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);

        overflow: hidden;
        height: 1.35em;

        @media ${({ theme }) => theme.breakpoints.md} {
        }

        &:hover,
        &:focus {
          outline: none;
        }

        & span {
          position: relative;
          display: inline-block;
          -webkit-transition: -webkit-transform 0.3s;
          -moz-transition: -moz-transform 0.3s;
          transition: transform 0.3s;

          &::before {
            position: absolute;
            top: 100%;
            content: attr(data-hover);
            font-weight: 700;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
        }

        &:hover span,
        &:focus span {
          text-decoration: underline;
        }

        @media ${({ theme }) => theme.breakpoints.lg} {
          &:hover span,
          &:focus span {
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            transform: translateY(-100%);
          }
        }
      }

      /* a + a {
        margin-left: 1em;
        @media ${({ theme }) => theme.breakpoints.md} {
          margin-left: 2em;
        }
      } */

      /* a:hover {
        text-decoration: underline;
      } */
    }

    li + li {
      margin-left: 1em;
      @media ${({ theme }) => theme.breakpoints.md} {
        margin-left: 2em;
      }
    }
  }
`;
