import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import rangeParser from "parse-numeric-range";
import theme from "prism-react-renderer/themes/dracula";

export const CodeBlock = ({ children, className, metastring }) => {
  // const className = props.children.props.className || "";
  const language = className.replace(/language-/, "") || "";

  // console.log(metastring);

  const calculateLinesToHighlight = (metastring) => {
    const RE = /\{([\d\s,-]+)\}/;

    if (RE.test(metastring)) {
      const strlineNumbers = RE.exec(metastring)[1];
      // console.log("strlineNumbers", strlineNumbers);
      const lineNumbers = rangeParser(strlineNumbers);
      // console.log("lineNumbers", lineNumbers);
      return (index) => lineNumbers.includes(index + 1);
    } else {
      return () => false;
    }
  };

  const shouldHighlightLine = calculateLinesToHighlight(metastring);

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style }}>
          {tokens.map((line, index) => {
            const lineProps = getLineProps({ line, key: index });
            if (shouldHighlightLine(index)) {
              lineProps.className = `${lineProps.className} highlight-line`;
            }
            return (
              <div key={index} {...lineProps}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            );
          })}
        </pre>
      )}
    </Highlight>
  );
};
