import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StyledImage } from "./styles";

export const Image = ({ image, type, alt }) => {
  // const imageStyles = {
  //   size: {
  //     // card: "300px",
  //     card: "100%",
  //     featured: "450px",
  //   },
  //   borderRadius: "4px",
  // };

  if (type === "featured") {
    return (
      <StyledImage type="featured" className="featured">
        <GatsbyImage
          // style={{
          //   height: imageStyles.size.featured,
          //   borderRadius: imageStyles.borderRadius,
          // }}
          image={image}
          alt={alt}
        />
      </StyledImage>
    );
  }
  if (type === "card") {
    return (
      <StyledImage hoverZoom="true" type="card" className="card">
        <GatsbyImage
          // style={{
          //   height: imageStyles.size.card,
          //   borderRadius: imageStyles.borderRadius,
          // }}
          image={image}
          alt={alt}
        />
      </StyledImage>
    );
  }
};
