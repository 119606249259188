// Prism highlighting
// import Code from "../components/Code";
// import { preToCodeBlock } from "mdx-utils";
// import Highlight, { defaultProps } from "prism-react-renderer";
// import "./language-tabs.css";

// import theme from "prism-react-renderer/themes/vsDark";

// import { Container } from "./src/components"

const size = {
  xs: "480px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
};

const theme = {
  content: {
    width: "85%",
    // maxWidth: "680px",
    maxWidth: "860px",
    margin: "0 auto",
  },
  fonts: {
    family: {
      heading: "Inter, sans-serif",
      subheading: "Inter, sans-serif",
      body: "Inter, sans-serif",
    },
    weight: {
      regular: "400",
      light: "300",
      bold: "700",
    },
    size: {
      heading: {
        sm: "2rem",
        md: "2.25rem",
        lg: "2.6667rem",
      },
      subheading: {
        sm: "1.25rem",
        md: "1.5rem",
        lg: "1.6667rem",
      },
      body: {
        sm: "1rem",
        md: "1.125rem",
        lg: "1.1667rem",
      },
    },
    // tracking = letter-spacing
    letterSpacing: {
      heading: "0.01rem", // tracking 10
      subheading: "0.028rem", // tracking 28
      body: "0.01rem", // tracking 10
    },
    // leading = line-height
    lineHeight: {
      subheading: "2rem", // leading 14
      // body: "2.333rem", // leading 28
      body: "2rem",
    },
  },
  textAlign: {},
  colors: {
    primaryBlue: "#65b3ff",
    blue: "hsl(195,100%,40%)",
    red: "hsl(353,100%,61%)",
    orange: "hsl(24,100%,56%)",
    yellowOrange: "hsl(44,100%,50%)",
    // darkBlue: "#1b2b4c",
    darkBlue: "hsl(220,48%,20%)",
    darkGray: "#333",
    heroBackground: "hsl(195,100%,90%)",
    heroText: "hsl(195, 100%, 40%)",
    // main1: "hsL(207,70%,59%)",
    // main2: "hsl(207,70%,94%)",
    // dark1: "hsl(227,2%,12%)",
    // dark2: "hsl(227,2%,26%)",
    // dark3: "hsl(227,2%,64%)",
    // light1: "hsl(0,0%,97%)",
    // light2: "hsl(0,0%,99%)",
  },
  // size: {
  //   sm: "640px",
  //   md: "768px",
  //   lg: "1024px",
  //   xl: "1280px",
  // },
  breakpoints: {
    mobile: "only screen and (max-width: 50rem)", // 800px
    tablet: "only screen and (max-width: 65rem)", // 1040
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    // sm: `(min-width: ${this.size.sm})`,
    // md: `(min-width: ${this.size.md})`,
    // lg: `(min-width: ${this.size.lg})`,
    // xl: `(min-width: ${this.size.xl})`,
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xLarge: "4rem",
    xxLarge: "6rem",
    // xxSmall: ".25em",
    // xSmall: ".5em",
    // small: "1em",
    // medium: "2em",
    // large: "3em",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30, 30, 31, 0.05)",
  },
  image: {
    size: {
      card: "200px",
      featured: "450px",
    },
    borderRadius: "4px",
  },
  font: {
    heading: "Inter, Roboto, sans-serif",
    subheading: "Inter, Roboto, sans-serif",
    body: "Inter, Roboto, sans-serif",
  },
  fontSize: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  spacing: {
    px: "1px",
    0: "0",
    1: "0.25rem",
    2: "0.5rem",
    3: "0.75rem",
    4: "1rem",
    5: "1.25rem",
    6: "1.5rem",
    8: "2rem",
    10: "2.5rem",
    12: "3rem",
    16: "4rem",
    20: "5rem",
    24: "6rem",
    32: "8rem",
    40: "10rem",
    48: "12rem",
    56: "14rem",
    64: "16rem",
  },
};

export default theme;
