import styled from "styled-components";
import { Wrapper } from "./Wrapper.styled";

export const StyledHero = styled(Wrapper)`
  /* background: ${(props) => props.theme.colors.heroBackground}; */
  margin: 0 auto 2em auto;
  /* to account for sticky header */
  /* margin: 10em auto 2em auto; */
  padding: 0 2em 2em 2em;

  /* height: 60vh; */

  @media ${({ theme }) => theme.breakpoints.lg} {
    margin: 0 auto 100px auto;
    /* to account for sticky header */
    /* margin-top: 150px; */
  }

  div {
    /* max-width: 1200px; */
    /* margin: 0 auto; */
    /* padding-top: 1rem;
  padding-bottom: 4rem; */
  }

  h1 {
    margin: 0 auto;

    @media ${(props) => props.theme.breakpoints.md} {
      font-size: 2rem;
      /* padding: 0.5em 0 1em 0; */
    }
    @media ${(props) => props.theme.breakpoints.lg} {
      font-size: 3rem;
      /* padding: 1em 0 2em 0; */
    }
    @media ${(props) => props.theme.breakpoints.xlg} {
      font-size: 3.5 rem;
      /* padding: 1em 0 2em 0; */
    }

    /* background: ${(props) => props.theme.colors.heroText}; */
  }
`;

export const HeroRow = styled.div`
  padding: ${({ padding }) => (padding ? padding : 0)};
`;

export const HeroText = styled.h1`
  font-size: ${({ theme }) => theme.fontSize["2xl"]};
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  /* margin: ${({ theme }) => theme.spacing[4]} ${({ theme }) =>
    theme.spacing[0]}; */
  line-height: ${({ theme }) => theme.lineHeight.tight};

  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: ${({ theme }) => theme.fonts.size.heading.md};
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    font-size: ${({ theme }) => theme.fonts.size.heading.lg};
  }

  animation: slide-up 1000ms;

  @keyframes slide-up {
    from {
      transform: translateY(30px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export const IconWrapper = styled.span`
  height: 32px;
  overflow: hidden;

  svg {
    animation: iconspin infinite 7s linear;
  }
  @keyframes iconspin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const JSLogo = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 0.25em;

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 32px;
    height: 32px;
  }

  @media ${({ theme }) => theme.breakpoints.lg} {
    width: 58px;
    height: 58px;
  }
`;

export const ColoredDivider = styled(Wrapper)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  background: ${({ theme }) => theme.colors.primaryBlue};

  @media ${({ theme }) => theme.breakpoints.sm} {
    height: 300px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    height: 400px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    height: 500px;
  }
`;

export const TypewriterTextWrapper = styled.div`
  /* border: 2px solid rebeccapurple; */
  /* height: 90px; */
  height: 120px;

  @media ${({ theme }) => theme.breakpoints.xs} {
    /* border: 2px solid blue; */
    height: 60px;
  }
  /* @media ${({ theme }) => theme.breakpoints.sm} {
    border: 2px solid orange;
    height: 60px;
  } */
  @media ${({ theme }) => theme.breakpoints.md} {
    /* border: 2px solid green; */
    height: 90px;
  }
  @media ${({ theme }) => theme.breakpoints.lg} {
    /* border: 2px solid red; */
    height: 125px;
  }
  /* @media ${({ theme }) => theme.breakpoints.xl} {
    border: 2px solid black;
    height: 90px;
  } */
`;
