import React from "react";
import { SocialWrapper } from "./styles";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

export const Social = () => (
  <SocialWrapper>
    <ul>
      <li>
        {/* <Link to="/projects">Projects</Link> */}
        <a
          color="#000000"
          href="https://github.com/petercurcio/"
          target="_blank"
          rel="noreferrer"
        >
          <FaGithub />
        </a>
      </li>
      {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
      <li>
        {/* <Link to="/about">About</Link> */}
        <a
          color="#0A66C2"
          href="https://www.linkedin.com/in/petercurcio/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin />
        </a>
      </li>
      <li>
        {/* <Link to="/contact">Contact</Link> */}
        <a
          color="#1DA1F2"
          href="https://twitter.com/PeterACurcio"
          target="_blank"
          rel="noreferrer"
        >
          <FaTwitter />
        </a>
      </li>
      <li>
        <a
          color="#EA4335"
          href="mailto:petercurcio.dev@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineMail />
        </a>
      </li>
    </ul>
    {/* </NavWrapper> */}
  </SocialWrapper>
);
