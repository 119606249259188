import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import {
  Container,
  StyledHero,
  HeroRow,
  HeroText,
  JSLogo,
  IconWrapper,
  HomepageContactButtons,
  TypewriterTextWrapper,
} from "../components/styles";
import { FaReact } from "react-icons/fa";
// import { SiJavascript } from "react-icons/si";
import { Typewriter } from "react-simple-typewriter";
import { ContactLinks, Button } from ".";
// import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
// import { AiOutlineMail } from "react-icons/ai";

export const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <HeroRow padding="4em 0 1em 0">
          <HeroGreetingStart />
        </HeroRow>
        <HeroRow padding="1em 0 0 0">
          <HeroGreetingEnd />
        </HeroRow>
        <HeroRow padding="0 0 1em 0">
          <HeroTypewriter />
        </HeroRow>
        {/* <br /> */}
        <HeroRow padding="0 0 1em 0">
          <HeroButtons />
        </HeroRow>
        <br />
        <HeroRow padding="0 0 1em 0">
          <HeroContactSection />
        </HeroRow>
      </Container>
    </StyledHero>
  );
};

const HeroGreetingStart = () => {
  return (
    <HeroText>
      Hi there, I'm Peter. <br />
      <span style={{ fontWeight: "700" }}>Software Developer </span>
      from Portland, Oregon. <br />
      {/* <div>
        I'm driven to:<span> </span>
      </div> */}
    </HeroText>
  );
};

const HeroGreetingEnd = () => {
  const logos = useStaticQuery(graphql`
    query {
      jsLogo: file(relativePath: { eq: "JS_logo_yellow.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <HeroText>
      <div>
        I use {/* I build magical experiences with{" "} */}
        {/* <SiJavascript /> */}
        <JSLogo>
          <img src={logos.jsLogo.publicURL} alt="javascript logo" />
        </JSLogo>
        and{" "}
        <IconWrapper>
          <FaReact style={{ fill: "#61dafb" }} />
        </IconWrapper>{" "}
        to:
        <br />
      </div>
    </HeroText>
  );
};

const HeroTypewriter = () => {
  return (
    <TypewriterTextWrapper>
      <HeroText>
        <Typewriter
          // words={[
          //   "I'm driven to build magical user experiences...",
          //   "I'm committed to constructing quality web applications...",
          //   "I'm delighted to code refreshingly-clean designs...",
          //   "I'm all about crafting creative solutions...",
          //   "I love to nerd out (naturally)...",
          // ]}
          // words={[
          //   "build magical user experiences...",
          //   "construct quality web applications...",
          //   "code refreshingly-clean designs...",
          //   "craft creative solutions...",
          //   "nerd out (naturally)...",
          // ]}
          // words={[
          //   "Build magical user experiences...",
          //   "Construct high-quality web applications...",
          //   "Code refreshingly-clean designs...",
          //   "Craft creative solutions...",
          // ]}
          // words={[
          //   "Build magical user experiences.",
          //   "Construct high-quality web applications.",
          //   "Code refreshingly-clean designs.",
          //   "Craft creative solutions.",
          // ]}
          words={[
            "Track wildfires using the ArcGIS API",
            "Search favorite books with the Google Books API",
            "Build speedy sites with Gatsby and GraphQL",
            "Easily generate customizable random passwords",
          ]}
          loop={0}
          cursor
          cursorStyle="_"
          typeSpeed={70}
          deleteSpeed={20}
          delaySpeed={1000}
          // onLoopDone={handleDone}
          // onType={handleType}
        />
      </HeroText>
    </TypewriterTextWrapper>
  );
};

const HeroButtons = () => {
  return (
    <HomepageContactButtons>
      <Link to="/projects">
        <Button>My Projects</Button>
      </Link>
      <Link to="/about">
        <Button>About Me</Button>
      </Link>
    </HomepageContactButtons>
  );
};

const HeroContactSection = () => {
  return <ContactLinks />;
};
