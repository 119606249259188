import styled from "styled-components";
import { Container } from ".";

export const Section = styled(Container)`
  margin: 50px auto;

  @media ${({ theme }) => theme.breakpoints.md} {
    margin: 100px auto 200px auto;
  }

  .homepage-section-title {
    font-size: 1.75rem;
    font-weight: 300;
    text-align: center;

    @media ${({ theme }) => theme.breakpoints.lg} {
      font-size: 2.75rem;
    }
  }
`;
