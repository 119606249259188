import styled from "styled-components";

export const StyledImage = styled.div`
  overflow: hidden;

  height: 200px;
  @media ${({ theme }) => theme.breakpoints.md} {
    height: ${({ type }) => (type === "featured" ? "450px" : "300px")};
  }

  img {
    transition: ${({ hoverZoom }) =>
      hoverZoom ? "transform 0.3s ease !important" : ""};

    &:hover {
      transform: ${({ hoverZoom }) => (hoverZoom ? "scale(1.1)" : "")};
    }

    height: 100%;

    @media ${({ theme }) => theme.breakpoints.md} {
      height: ${({ type }) => (type === "featured" ? "450px" : "100%")};
    }
  }
`;

export const mdxInlineImageWrapper = styled.div`
  margin: 0;
  /* margin-left: auto; */
  margin: ${({ margin }) =>
    margin === "left"
      ? "0 0 0 auto"
      : margin === "right"
      ? "0 auto 0 0"
      : "0 auto"};
  width: 70%;
  margin-top: 2em;
  box-shadow: 0 5px 5px 0 rgb(233 240 243 / 50%), 0 0 0 1px #e6ecf8;
  border-radius: 12px;
  overflow: hidden;

  /* @media ${({ theme }) => theme.breakpoints.md} {
    margin-left: auto;
    margin-right: auto;
    max-width: 3999px;
  } */

  p {
    margin-top: 0;
  }
`;

export const FullWidth = styled.div`
  background: ${({ background }) => (background ? background : "none")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;
