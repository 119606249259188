import styled from "styled-components";

export const StyledButton = styled.button`
  color: #fff !important;
  padding: 0.65em 2em;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0.65em 2.5em;
  }

  border-radius: 0.375rem;
  width: fit-content;

  color: hsl(195, 100%, 40%) !important;
  background-color: transparent;
  border: 2px solid hsl(195, 100%, 40%);
  transition: color 0.25s, background-color 0.25s, border-color 0.25s;

  &:hover {
    opacity: 1;
    background-color: hsl(195, 100%, 40%);
    color: #fff !important;
    border: 2px solid hsl(195, 100%, 40%);
    cursor: pointer;
  }
`;

export const StyledButtonLink = styled.a`
  color: #fff !important;
  /* background: ${(props) => props.theme.colors.blue}; */
  padding: 0.65em 2em;

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0.65em 2.5em;
  }

  border-radius: 0.375rem;
  width: fit-content;

  /* Updated styles */
  /* color: #207bea !important;  */
  color: hsl(195, 100%, 40%) !important;
  /* color: ${(props) => props.theme.colors.blue} !important; */
  background-color: transparent;
  /* color: #207bea; */
  /* border: 1px solid #207bea; */
  border: 2px solid hsl(195, 100%, 40%);
  /* border: 2px solid ${(props) => props.theme.colors.blue}; */
  transition: color 0.25s, background-color 0.25s, border-color 0.25s;

  &:hover {
    /* opacity: 0.7;
    transition: opacity 300ms; */
    opacity: 1;
    /* background-color: #207bea; */
    background-color: hsl(195, 100%, 40%);
    /* background-color: ${(props) => props.theme.colors.blue}; */
    color: #fff !important;
    border: 2px solid hsl(195, 100%, 40%);
    /* border: 2px solid ${(props) => props.theme.colors.blue}; */
    cursor: pointer;
  }
`;

export const ButtonWrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */

  /* padding: 0.5rem 0.75rem; */
  height: 50px;
  padding: 0.75rem 4rem;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 0.375rem;
  /* color: ${(props) => props.theme.colors.light1}; */
  color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: none;
  font-weight: 700;
  font-size: 0.875rem;
  /* width: fit-content; */
  width: 200px;
  transition: filter 0.3s ease;
  text-decoration: none;

  &:hover,
  &:focus {
    filter: brightness(110%);
  }
`;

export const ContactFormButton = styled.button`
  font-weight: bold;
  color: #fff;
  padding: 0.65em 2.5em;
  width: fit-content;
  background-color: hsl(195, 100%, 40%);
  border: 2px solid hsl(195, 100%, 40%);
  transition: opacity 0.3s ease 0s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const HomepageContactButtons = styled.div`
  display: flex;
  flex-direction: column;

  /* @media ${({ theme }) => theme.breakpoints.xs} { */
  @media (min-width: 375px) {
    flex-direction: row;
  }

  a + a {
    margin-top: 1em;

    /* @media ${({ theme }) => theme.breakpoints.xs} { */
    @media (min-width: 375px) {
      margin-top: 0;
      margin-left: 0.5em;
    }

    @media ${({ theme }) => theme.breakpoints.xs} {
      margin-left: 1em;
    }
  }
`;
