import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2em;

  /* border: 1px solid blue; */

  @media ${({ theme }) => theme.breakpoints.md} {
    padding: 0 2em;
  }
`;
